import { Col, Container, Row } from 'react-grid-system'
import { Link } from 'react-router-dom'

import { Content, PageHeader, Panel, Spinner, Steps } from '@/presentation/components'

import { ArrowRightIcon } from './assets/ArrowRightIcon'
import { steps } from './utils/steps'

import { BackToList, Card, ContainerCards, StepsWrapper } from './styles'
import { useOperatorCards } from '@/main/hooks/useOperatorCards'
import { useEffect } from 'react'
import { useAccessProfile } from '@/main/hooks/useAccessProfile'

interface ICardsSelection {
  linkToNext: string
  linkToBack: string
  title: string
  resource?: string
  checkResource?: string
}

export const CardsSelection: React.FC<ICardsSelection> = ({
  linkToBack,
  linkToNext,
  title,
  resource,
  checkResource,
}) => {
  const { checkSubRoute } = useAccessProfile({ resource })

  const { cards, isLoading } = useOperatorCards()

  useEffect(() => {
    if (resource && checkResource) {
      checkSubRoute({ resource: checkResource })
    }
  }, [])

  return (
    <Content>
      <Panel noPadding>
        <PageHeader borderless={undefined} location={undefined}>
          {title}
        </PageHeader>
        <Container fluid style={{ padding: '32px' }}>
          {isLoading ? (
            <Spinner.Box>
              <Spinner />
            </Spinner.Box>
          ) : (
            <Row>
              <Col>
                <StepsWrapper>
                  <Steps currentStep={1} steps={steps} />
                </StepsWrapper>
              </Col>
            </Row>
          )}
          <>
            <Row justify='center'>
              <Col md='content'>
                <ContainerCards>
                  {cards.map(({ operatorName, operatorCardName, operatorCardId }) => {
                    return (
                      <Card key={operatorCardName}>
                        <Link
                          to={linkToNext}
                          state={{ operatorCardId, operatorCardName, operatorName, isAbleToChange: true }}
                        >
                          {/* <img src={image} /> */}
                          <div>
                            <p>{operatorCardName}</p>
                            <small>{operatorName}</small>
                          </div>

                          <ArrowRightIcon />
                        </Link>
                      </Card>
                    )
                  })}
                </ContainerCards>
              </Col>
            </Row>

            <Row justify='center'>
              <Col md='content'>
                <BackToList>
                  <Link to={linkToBack}>Voltar a listagem</Link>
                </BackToList>
              </Col>
            </Row>
          </>
        </Container>
      </Panel>
    </Content>
  )
}
