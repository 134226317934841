import { useMemo, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { Container, Col, useScreenClass } from 'react-grid-system'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { Row } from '../../styles'
import { Table, Message } from 'presentation/components'
import {
  DropdownButton,
  DropdownButtonItem,
  OrderCancelationModal,
  Search,
  TableFooterPagination,
} from 'presentation/components/molecules'
import { getCardsOrders } from 'store/modules/orders/cards/all/actions'
import { FIVE_SECONDS } from 'utils/constants'
import paginatorMethods from 'utils/paginatorMethods'
import { ReactComponent as CirclePlusIcon } from 'presentation/assets/icons/circle-plus.svg'

import { useCardsOrders } from 'presentation/pages/cards/tabs/orders/hooks/useCardsOrders'
import { Filter } from 'presentation/components/organisms'
import { orderStatus } from 'utils/options'
import { TableBody } from './components/TableBody/TableBody'
import { TableHead } from './components/TableHead/TableHead'

import { Button } from 'presentation/components/atoms'
import { useBenefitTypes } from 'main/hooks/useBenefitTypes'
import { usePaginationOld } from 'main/hooks/usePaginationOld'
import { useCancelOrder, useCartOrdersByOrderId } from 'main/hooks'
import { NULL_ID } from 'utils/constants'
import { IOrderBasicInformation } from 'main/interfaces/iOrderBasicInformation'
import { useNavigate } from 'react-router-dom'
import { useOperatorCards } from '@/main/hooks/useOperatorCards'

const Orders = () => {
  const { cards: operators } = useOperatorCards()
  const shouldShowButtonNewOrder = operators?.length >= 1

  const navigate = useNavigate()
  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)

  const [maxDate, setMaxDate] = useState<any>(dayjs(new Date()))
  const [selectedInitDate, setSelectedIniDate] = useState(false)

  const { benefitTypes, loading } = useBenefitTypes()
  const { cards, cardsPagination, isLoading: isLoadingCard } = useCardsOrders()

  const dateMax = new Date(maxDate)
  dateMax.setDate(dateMax.getDate() + 180)

  const newMaxDate = moment(new Date(maxDate)).diff(new Date(), 'days') < -180 ? dayjs(dateMax) : dayjs(new Date())

  const dispatch = useDispatch()
  const [filterModal, setFilterModal] = useState(false)
  const screenClass = useScreenClass()

  const query = {
    Sort: 'code',
    desc: true,
  }
  const { paginator, setPaginator } = usePaginationOld({ query })

  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState<boolean>(false)
  const [orderToCancel, setOrderToCancel] = useState<IOrderBasicInformation | null>(null)
  const isOrderToCancelACart: boolean = orderToCancel?.cartId !== NULL_ID && Boolean(orderToCancel?.cartId)

  const { cancelOrder, cancelCartOrders } = useCancelOrder({
    onSuccess: () => {
      setIsCancelDialogOpen(false)
      setOrderToCancel(null)
      setPaginator(paginatorMethods.refresh(paginator))
    },
  })

  const { cartOrders: cartOrdersToCancel, isLoading: isCartOrdersLoading } = useCartOrdersByOrderId({
    orderId: isOrderToCancelACart && orderToCancel ? orderToCancel.id : null,
  })

  const handleCancelClick = ({ order }: { order: IOrderBasicInformation }) => {
    setOrderToCancel(order)
    setIsCancelDialogOpen(true)
  }

  const onCancelConfirm = ({ idsToCancel }: { idsToCancel: Array<string> }) => {
    if (!isOrderToCancelACart && orderToCancel?.id) {
      cancelOrder.mutate({ orderId: orderToCancel?.id })
    } else {
      cancelCartOrders.mutate(idsToCancel)
    }
  }

  useEffect(() => {
    const hasInitDate = paginator.query?.initDate
    const hasEndDate = paginator.query?.endDate

    if (hasInitDate || hasEndDate) {
      const initDate = new Date(paginator.query?.initDate)?.toLocaleDateString().replaceAll('/', '-')
      const endDate = new Date(paginator.query?.endDate)?.toLocaleDateString().replaceAll('/', '-')

      delete paginator?.query?.initDate
      delete paginator?.query?.endDate

      const OrderDate = `${initDate}><${endDate}`

      paginator.doRequest &&
        dispatch(
          getCardsOrders(paginatorMethods.buildQuery({ ...paginator, query: { ...paginator.query, OrderDate } })),
        )

      return
    }

    paginator.doRequest && dispatch(getCardsOrders(paginatorMethods.buildQuery(paginator)))
    // eslint-disable-next-line
  }, [paginator])

  useEffect(() => {
    setPaginator(
      paginatorMethods.updatePaginator(paginator, {
        ...cardsPagination,
      }),
    )
    // eslint-disable-next-line
  }, [cards])

  const search = (value: any) => {
    setPaginator(
      paginatorMethods.setFilters(paginator, {
        filterValue: value,
        filterProps: 'code',
      }),
    )
  }

  useMemo(() => {
    setTimeout(() => {
      error && setError(null)
      success && setSuccess(null)
    }, FIVE_SECONDS)
    success && setPaginator(paginatorMethods.refresh(paginator))
    // eslint-disable-next-line
  }, [error, success])

  return (
    <Container style={{ padding: 0, maxWidth: '100%' }}>
      <OrderCancelationModal
        open={isCancelDialogOpen}
        orders={isOrderToCancelACart ? cartOrdersToCancel : [orderToCancel]}
        onCancel={() => setIsCancelDialogOpen(false)}
        onConfirm={onCancelConfirm}
        isCart={isOrderToCancelACart}
        isLoading={cancelOrder.isLoading || (isCartOrdersLoading && isOrderToCancelACart)}
        defaultCheckedId={isOrderToCancelACart ? orderToCancel?.id : undefined}
      />
      <Row justify='flex-start' align='center'>
        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
          <Search onSearch={search} placeholder='Buscar na tabela abaixo ...' />
        </Col>
        <Col
          xs={12}
          md={2}
          sm={6}
          style={{
            padding: ['xs', 'sm'].includes(screenClass) ? 15 : 0,
          }}
        >
          <Button
            startIcon={<FontAwesomeIcon icon={faFilter} />}
            variant='outlined'
            onClick={() => setFilterModal(true)}
            fullWidth={['xs'].includes(screenClass)}
          >
            Filtrar
          </Button>
        </Col>
        <Col xs={12} sm={6} md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {shouldShowButtonNewOrder ? (
            <DropdownButton
              label='Novo Pedido'
              startIcon={<CirclePlusIcon />}
              sx={{ '& svg': { height: '24px' } }}
              size='medium'
              id='novo_pedido_cartoes'
            >
              <DropdownButtonItem
                id='novo_pedido_cartoes_1_via'
                onClick={() =>
                  operators.length <= 1
                    ? navigate('/cartoes/novo', {
                        state: {
                          operatorCardId: operators?.[0]?.operatorCardId,
                          operatorCardName: operators?.[0]?.operatorCardName,
                          operatorName: operators?.[0]?.operatorName,
                        },
                      })
                    : navigate('/cartoes/novo/selecionar')
                }
              >
                1ª Via do Cartão
              </DropdownButtonItem>
              <DropdownButtonItem
                onClick={() =>
                  operators.length <= 1
                    ? navigate('/cartoes/novo/segunda', {
                        state: {
                          operatorCardId: operators?.[0]?.operatorCardId,
                          operatorCardName: operators?.[0]?.operatorCardName,
                          operatorName: operators?.[0]?.operatorName,
                        },
                      })
                    : navigate('/cartoes/novo/segunda/selecionar')
                }
                id='novo_pedido_cartoes_2_via'
              >
                {' '}
                2ª Via do Cartão
              </DropdownButtonItem>
            </DropdownButton>
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col>
          <Table loading={isLoadingCard} maxHeight={''}>
            <TableHead paginator={paginator} setPaginator={setPaginator} />
            <TableBody cards={cards} cancelCardOrderRequest={handleCancelClick} />
          </Table>
        </Col>
      </Row>

      <Row justify={['xs', 'sm'].includes(screenClass) ? 'center' : 'between'} align='center'>
        <TableFooterPagination
          onPageChange={(_e, value) => setPaginator(paginatorMethods.setPage(paginator, value))}
          onRowsPerPageChange={(e) => setPaginator(paginatorMethods.setPageSize(paginator, e.target.value))}
          totalPages={paginator.totalPages}
          totalItems={cardsPagination?.totalItens}
          page={paginator.page}
          rowsPerPage={paginator.pageSize}
          hideDivider
        />
      </Row>
      <Filter
        open={filterModal}
        isLoading={loading}
        onClose={() => {
          setFilterModal(false)
          setSelectedIniDate(false)
          setMaxDate(dayjs(new Date()))
        }}
        onFilter={(value) => {
          const filterValues = Object.keys(value).reduce((final, actual) => {
            const newFinal = { ...final }
            if (value[actual].length !== 0 && value[actual] !== '') newFinal[actual] = value[actual]
            return newFinal
          }, {})
          setPaginator(paginatorMethods.setFilters(paginator, filterValues))
          setFilterModal(false)
        }}
        onClean={() => {
          setPaginator(paginatorMethods.resetFilters(paginator))
          setMaxDate(dayjs(new Date()))
          setSelectedIniDate(false)
        }}
        fields={[
          {
            type: 'multiSelect',
            name: 'BenefitType',
            props: {
              label: 'Benefícios',
              fullWidth: true,
              variant: 'contained',
              options: benefitTypes,
            },
          },
          {
            type: 'select',
            name: 'status',
            props: {
              label: 'Status',
              fullWidth: true,
              options: orderStatus,
            },
          },
          {
            type: 'date',
            name: 'initDate',
            props: {
              label: 'Data Inicial',
              fullWidth: true,
              maxDate: dayjs(new Date()),
              setMaxDate,
              setSelectedIniDate,
            },
          },
          {
            type: 'date',
            name: 'endDate',
            rules: { required: selectedInitDate },
            props: {
              label: 'Data Final',
              fullWidth: true,
              maxDate: newMaxDate,
              minDate: maxDate,
              disabled: !selectedInitDate,
            },
          },
        ]}
      />
      {success && (
        <Message type='ok' onXClick={() => setSuccess(0)}>
          {success}
        </Message>
      )}
      {error && (
        <Message type='error' onXClick={() => setError(0)}>
          {error}
        </Message>
      )}
    </Container>
  )
}

export default Orders
