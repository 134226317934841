import { useAtom } from 'jotai'
import { atomWithHash } from 'jotai-location'
import { ButtonSelector, ButtonSelectorOption } from '@stationkim/front-ui'
import { OldDealerList } from './oldDealerList'
import { RechargesList } from './rechargesList'
import { getResaleSiteFromUrl } from '@/utils/functionUtils'
import { useAccessProfile } from '@/main/hooks/useAccessProfile'

const lists = [RechargesList, OldDealerList]
const listSelectorAtom = atomWithHash('selectedList', 0)

const Recharges = () => {
  const { resources } = useAccessProfile({ resource: 'recarga' })

  const [visibleList, setVisibleList] = useAtom(listSelectorAtom)

  const isMotiva = getResaleSiteFromUrl() === 'motiva'
  const listSelector = !isMotiva ? (
    <ButtonSelector value={visibleList} onChange={({ targetIndex }) => setVisibleList(targetIndex)}>
      {resources({ item: 'recarga_pedidobroker' }) ? <ButtonSelectorOption>Broker</ButtonSelectorOption> : <p />}
      <ButtonSelectorOption>Dealer</ButtonSelectorOption>
    </ButtonSelector>
  ) : null

  const Component = isMotiva ? lists[1] : lists[visibleList]
  return <Component listSelector={listSelector} />
}

export default Recharges
