import { useContext, useEffect, useState } from 'react'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { Button, Divider, Grid, Icon, Tooltip, Typography } from 'presentation/components/atoms'
import { OrderStatusComponentsProps } from '../../orderStatusComponentsProps'
import { TextField } from 'presentation/components/molecules'
import { OrderTrackingContext } from '@/presentation/pages/orderTracking/oldOrderTracking/orderTracking'

export const Slip = ({ paymentType }: OrderStatusComponentsProps) => {
  const { slip } = useContext(OrderTrackingContext)
  const [isCopyTipOpen, setIsCopyTipOpen] = useState(false)

  useEffect(() => {
    if (isCopyTipOpen) setTimeout(() => setIsCopyTipOpen(false), 500)
  }, [isCopyTipOpen])

  const copySlipBarCode = async () => {
    if (slip) await navigator.clipboard.writeText(slip.barcodeNumber)
  }

  const downloadSlip = () => {
    if (slip) window.open(slip?.slipLink, slip?.barcodeNumber)
  }

  return (
    <>
      <Typography variant='h3' sx={{ fontWeight: 500 }}>
        Pague o valor do pedido antes do vencimento do boleto
      </Typography>
      <Typography sx={{ marginTop: '32px' }}>
        {['SLIP', 'TERM_BILL']?.includes(paymentType.join(' ')) && (
          <>
            Para efetuar o pagamento, copie o código numérico ou escaneie o código de barras.
            <br />
            Para pagar em qualquer banco ou lotérica, por favor, baixe e imprima o boleto.
            <br />
            <br />
          </>
        )}
        Caso já tenha efetuado o pagamento, aguarde até dois dias úteis para a compensação.
      </Typography>
      {['SLIP', 'TERM_BILL']?.includes(paymentType.join(' ')) && (
        <>
          <Divider sx={{ margin: '32px 0' }} />
          <Grid container columnSpacing='16px' rowSpacing='8px'>
            <Grid item xs={12} lg={9}>
              <TextField
                placeholder='00000000000000000000000000'
                value={slip?.barcodeNumber}
                sx={{ caretColor: 'transparent' }}
                endAdornment={
                  <Tooltip
                    title='Código copiado'
                    placement='top'
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    open={isCopyTipOpen}
                  >
                    <Icon
                      icon={faCopy}
                      onClick={() => {
                        setIsCopyTipOpen(true)
                        copySlipBarCode()
                      }}
                      sx={(theme: any) => ({
                        cursor: 'pointer',
                        color: theme.palette.primary.main + '!important',
                      })}
                    />
                  </Tooltip>
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={'auto'}>
              <Button variant='contained' fullWidth size='medium' onClick={copySlipBarCode}>
                Copiar código numérico
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md='auto'>
              <Button variant='outlined' fullWidth size='medium' onClick={downloadSlip}>
                Baixar boleto
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}
