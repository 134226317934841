import React, { useEffect, useState } from 'react'

import { Content, Background, Breadcrumb, Panel, Title, Spacing } from 'presentation/components'

import { Category, Categories, Container, ReportsList, Group, SubTitle } from './style'

import Report from './report'
import { REPORTS_CATEGORIES } from '@/utils/constants'
import SinteticCardBalanceReportFilter from './modals/syntheticCardBalanceReportFilter'
import useDownloadReport from 'main/hooks/useDownloadReport'
import {
  getBlockedCardsWithoutSecondCopyReport,
  getCardOrdersReport,
  getCardsLoadedAndNoLoaded,
  getCardsWithoutRechargeReport,
  getRechargeOrdersReport,
} from '@/services/reportsServices'
import AnaliticCardBalanceReportFilter from './modals/analiticCardBalanceReportFilter'
import GenericOrderReportFilter from './modals/genericOrderReportFilter'
import BalanceProjectionOrderReportFilter from './modals/balanceProjectionOrderReportFilter'
import { useAccessProfile } from '@/main/hooks/useAccessProfile'
import { reportsRoles } from './utils/roles'

const Reports = () => {
  const { resources, roles } = useAccessProfile({ resource: 'relatorio' })

  const hasAllOptions = Object.entries(REPORTS_CATEGORIES)?.every((category) =>
    reportsRoles[category[1]]?.every?.((role) => roles.includes(role)),
  )

  const [filterBy, setFilterBy] = useState(hasAllOptions ? null : 'Pedidos')
  const [filterModalsVisibility, setFilterModalsVisibility] = useState({})
  const [cardOrderReport, downloadCardOrderReport] = useDownloadReport((order, format) =>
    getCardOrdersReport(order, format),
  )
  const [cardsWithoutRechargeReport, downloadCardsWithoutRechargeReport] = useDownloadReport(() =>
    getCardsWithoutRechargeReport('?days=90'),
  )
  const [cardLoadedAndNoLoaded, downloadCardLoadedAndNoLoaded] = useDownloadReport(getCardsLoadedAndNoLoaded)
  const [blockedCardsWithoutSecondCopyReport, downloadBlockedCardsWithoutSecondCopyReport] = useDownloadReport(
    getBlockedCardsWithoutSecondCopyReport,
  )
  const [rechargeOrderReport, downloadRechargeOrderReport] = useDownloadReport((order, format) =>
    getRechargeOrdersReport(order, format),
  )

  const toggleFilterModalVisibility = (filterName) => {
    setFilterModalsVisibility((state) => {
      const newState = { ...state }
      if (newState[filterName]) {
        newState[filterName] = false
      } else newState[filterName] = true
      return newState
    })
  }

  const hideCategory = (category) => {
    const hasPermission = !reportsRoles[category]?.some((role) => roles.includes(role))

    return hasPermission
  }

  const hideGroup = (category) => {
    if (filterBy && filterBy !== category) {
      return true
    }

    const hasPermission = !reportsRoles[category]?.some((role) => roles.includes(role))

    return hasPermission
  }

  return (
    <>
      <Content>
        <Title>Relatórios</Title>
        <Spacing top='9px' bottom='9px'>
          <Breadcrumb />
        </Spacing>
        <Panel noPadding>
          <Container>
            <Categories>
              <SubTitle>Categorias</SubTitle>
              {hasAllOptions ? (
                <Category active={filterBy === null} onClick={() => setFilterBy(null)}>
                  Todos
                </Category>
              ) : null}
              {Object.keys(REPORTS_CATEGORIES).map((category, index) => {
                return (
                  <Category
                    key={`report_category_${index}`}
                    active={filterBy === REPORTS_CATEGORIES[category]}
                    onClick={() => setFilterBy(REPORTS_CATEGORIES[category])}
                    hidden={hideCategory(REPORTS_CATEGORIES[category])}
                  >
                    {REPORTS_CATEGORIES[category]}
                  </Category>
                )
              })}
            </Categories>
            <ReportsList>
              <SubTitle>Relatórios</SubTitle>
              <Group hidden={hideGroup(REPORTS_CATEGORIES.ORDERS)}>
                {resources({ item: 'relatorio_pedido_recarga' }) ? (
                  <Report generateReport={() => toggleFilterModalVisibility('rechargeOrderReport')}>
                    Pedido de Recarga
                  </Report>
                ) : null}
                {resources({ item: 'relatorio_pedido_recarga_projecao' }) ? (
                  <Report generateReport={() => toggleFilterModalVisibility('balanceProjectionOrderReport')}>
                    Pedido de Recarga Com Projeção de Saldo
                  </Report>
                ) : null}
                {resources({ item: 'relatorio_pedido_cartao' }) ? (
                  <Report generateReport={() => toggleFilterModalVisibility('cardOrderReport')}>
                    Pedido de Cartão
                  </Report>
                ) : null}
              </Group>
              <Group hidden={hideGroup(REPORTS_CATEGORIES.CARDS)}>
                {resources({ item: 'relatorio_cartoes_sempedido' }) ? (
                  <Report
                    generateReport={() =>
                      downloadCardsWithoutRechargeReport('Relatório de cartões sem pedido por 90 dias.pdf')
                    }
                    loading={cardsWithoutRechargeReport.loading}
                  >
                    Cartões sem Pedido por 90 Dias
                  </Report>
                ) : null}
                {resources({ item: 'relatorio_cartoes_bloqueado' }) ? (
                  <Report
                    generateReport={() =>
                      downloadBlockedCardsWithoutSecondCopyReport('Relatório de cartões bloqueados sem 2ª via.pdf')
                    }
                    loading={blockedCardsWithoutSecondCopyReport.loading}
                  >
                    Cartões Bloqueados sem 2ª Via
                  </Report>
                ) : null}
                {resources({ item: 'relatorio_cartoes_carregados_naocarregados' }) ? (
                  <Report generateReport={() => toggleFilterModalVisibility('cardLoadedAndNoLoadedReport')}>
                    Cartões Carregados e Não Carregados
                  </Report>
                ) : null}
              </Group>
              <Group hidden={hideGroup(REPORTS_CATEGORIES.BALANCE)}>
                {resources({ item: 'relatorio_saldo_analitico' }) ? (
                  <Report generateReport={() => toggleFilterModalVisibility('cardAnaliticBalanceReport')}>
                    Saldo Analítico
                  </Report>
                ) : null}
                {resources({ item: 'relatorio_saldo_sintetico' }) ? (
                  <Report generateReport={() => toggleFilterModalVisibility('cardSinteticBalanceReport')}>
                    Saldo Sintético
                  </Report>
                ) : null}
              </Group>
            </ReportsList>
          </Container>
        </Panel>
      </Content>
      {filterModalsVisibility['cardOrderReport'] && (
        <GenericOrderReportFilter
          open={filterModalsVisibility['cardOrderReport']}
          loading={cardOrderReport.loading}
          title='Relatório de Pedido de Cartão'
          onConfirm={(orderNumber, format) =>
            downloadCardOrderReport(`Relatório de pedido de cartão ${orderNumber}.${format}`, orderNumber, format)
          }
          onClose={() => toggleFilterModalVisibility('cardOrderReport')}
          availableFormatsOptions={[
            { value: 'pdf', label: 'PDF' },
            { value: 'xlsx', label: 'Excel ( *.xlsx)' },
          ]}
        />
      )}
      {filterModalsVisibility['rechargeOrderReport'] && (
        <GenericOrderReportFilter
          open={filterModalsVisibility['rechargeOrderReport']}
          loading={rechargeOrderReport.loading}
          title='Relatório de Pedido de Recarga'
          onConfirm={(orderNumber, format) =>
            downloadRechargeOrderReport(`Relatório de pedido de recarga ${orderNumber}.${format}`, orderNumber, format)
          }
          availableFormatsOptions={[
            { value: 'pdf', label: 'PDF' },
            { value: 'xlsx', label: 'Excel ( *.xlsx)' },
          ]}
          onClose={() => toggleFilterModalVisibility('rechargeOrderReport')}
        />
      )}
      {filterModalsVisibility['balanceProjectionOrderReport'] && (
        <BalanceProjectionOrderReportFilter
          open={filterModalsVisibility['balanceProjectionOrderReport']}
          loading={false}
          title='Relatório de pedido de recarga com projeção de saldo '
          onConfirm={(inputData) => inputData}
          onClose={() => toggleFilterModalVisibility('balanceProjectionOrderReport')}
          availableFormatsOptions={[
            { value: 'pdf', label: 'PDF' },
            { value: 'xlsx', label: 'Excel ( *.xlsx)' },
          ]}
        />
      )}
      {filterModalsVisibility['cardLoadedAndNoLoadedReport'] && (
        <GenericOrderReportFilter
          open={filterModalsVisibility['cardLoadedAndNoLoadedReport']}
          loading={cardLoadedAndNoLoaded.loading}
          title='Relatório de Cartões Carregados e Não Carregados'
          onConfirm={(orderNumber) =>
            downloadCardLoadedAndNoLoaded(`Relatório de cartões carregados e não carregados.pdf`, orderNumber)
          }
          onClose={() => toggleFilterModalVisibility('cardLoadedAndNoLoadedReport')}
        />
      )}
      {filterModalsVisibility['cardAnaliticBalanceReport'] && (
        <AnaliticCardBalanceReportFilter
          open={filterModalsVisibility['cardAnaliticBalanceReport']}
          onClose={() => toggleFilterModalVisibility('cardAnaliticBalanceReport')}
          availableFormatsOptions={[
            { value: 'pdf', label: 'PDF' },
            { value: 'xlsx', label: 'Excel ( *.xlsx)' },
          ]}
        />
      )}
      {filterModalsVisibility['cardSinteticBalanceReport'] && (
        <SinteticCardBalanceReportFilter
          open={filterModalsVisibility['cardSinteticBalanceReport']}
          onClose={() => toggleFilterModalVisibility('cardSinteticBalanceReport')}
        />
      )}
    </>
  )
}

export default Reports
