import _ from 'lodash'
import { useMediaQuery, useTheme } from '@mui/material'
import { Step, StepLabel, Stepper } from 'presentation/components/atoms'
import { ORDER_STATUS } from 'utils/constants'
import { convertStatusToStep } from '../utils/convertStatusToStep'

export const StatusStepper = ({
  status,
  statusArray,
  isFinancedSales,
}: {
  status: number
  statusArray: Array<string>
  isFinancedSales: boolean
}) => {
  const theme = useTheme()
  const isUnderMd = useMediaQuery(theme.breakpoints.down('md'))

  const isCanceled = status >= ORDER_STATUS.CANCELED
  return (
    <Stepper sx={{ margin: '0 0 32px' }} activeStep={status} orientation={isUnderMd ? 'vertical' : 'horizontal'}>
      {isCanceled ? (
        <Step sx={{ margin: 'auto' }}>
          <StepLabel>Cancelado</StepLabel>
        </Step>
      ) : (
        statusArray.map((statusName: string) => (
          <Step key={_.uniqueId()}>
            <StepLabel>{statusName}</StepLabel>
          </Step>
        ))
      )}
    </Stepper>
  )
}
