import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { CardSelectionDialog } from './cardSelectionDialog'
import { FeeConfigurationDialog } from './feeConfigurationDialog'
import useService from 'main/hooks/useService'
import { getBenefitToEdit, getBenefitsCards, getCardsFees, postNewBenefit, putEditBenefit } from 'services/benefit'
import { useDispatch } from 'react-redux'
import { pushNotification } from 'store/modules/notification/actions'
import successHandler from 'utils/successHandler'
import { BINDED_BENEFIT_STATUS } from 'utils/constants'
import { BindVisualization } from './bindVisualization'

export const BindCardToEmployeeMode = {
  add: 0,
  view: 1,
  edit: 2,
}

export const defaultValue = {
  benefitType: '',
  operator: '',
  operatorRegistration: '',
  operatorCard: '',
  cardNumber: '',
  dailyValueTotal: '',
  status: BINDED_BENEFIT_STATUS.ACTIVE,
  productsIds: [],
  products: [{ productId: '', quantity: '', dailyValue: '0' }],
  dailyValue: '',
}

export const BindCardToEmployees = ({
  employeeId,
  open,
  onClose,
  onSuccess,
  mode = BindCardToEmployeeMode.add,
  setMode = () => null,
  benefitToEdit,
  benefitsEdit,
}) => {
  const dispatch = useDispatch()
  const [edit, setEdit] = useState('')
  const isAdding = mode === BindCardToEmployeeMode.add
  const [isFeeConfigurationOpen, setIsFeeConfigurationOpen] = useState(false)
  const [isCardSelectionOpen, setIsCardSelectionOpen] = useState(
    (open && isAdding) || (benefitToEdit && mode === BindCardToEmployeeMode.edit),
  )
  const form = useForm({
    defaultValues: defaultValue,
  })
  const [getBenefitsState, getBenefitsRequest] = useService(getBenefitsCards, {})
  const [getCardFeesState, getCardFeesRequest] = useService(getCardsFees, {
    onCompleted: (res) => {
      const rates = res.data.dailyRates
      const ratesInUse = rates.filter((product) => product.enabled)
      const selectedRatesIds = ratesInUse.map((product) => product.productId)
      const selectedRates = ratesInUse.map((product) => ({
        productId: product.productId,
        quantity: product.quantity,
      }))
      if (res.data.operatorRegistration && mode === BindCardToEmployeeMode.add) setMode(BindCardToEmployeeMode.edit)
      form.setValue('operatorRegistration', res.data.operatorRegistration || '', { shouldValidate: true })
      form.setValue('cardNumber', res.data.cardNumber || '', { shouldValidate: true })
      form.setValue('products', selectedRates || '', { shouldValidate: true })
      form.setValue('productsIds', selectedRatesIds || '', { shouldValidate: true })
      if (Boolean(res.data.dailyValue)) form.setValue('dailyValue', res.data.dailyValue)
    },
  })

  const [postBindBenefitState, postBindBenefitRequest] = useService(postNewBenefit, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler('Benefício vinculado!')))
      onSuccess()
      setIsCardSelectionOpen(false)
      setIsFeeConfigurationOpen(false)
      form.reset(defaultValue)
    },
  })

  const [putEditBindedBenefitState, putEditBindedBenefitRequest] = useService(putEditBenefit, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler('Benefício editado!')))
      onSuccess()
      setIsCardSelectionOpen(false)
      setIsFeeConfigurationOpen(false)
      form.reset(defaultValue)
    },
  })

  const benefits = getBenefitsState.response?.data?.benefitsTypes || []
  const cardsFeesData = getCardFeesState.response?.data || []

  const [getBenefitToEditState, getBenefitToEditRequest] = useService(getBenefitToEdit, {
    onCompleted: async (res) => {
      const benefitUnderEdition = res.data
      const benefitsTypes = benefits.filter((type) => type.operators.length > 0) || []

      await getCardFeesRequest(employeeId, benefitUnderEdition.operatorCardId, benefitToEdit.cardNumber)
      let cardOperator = null
      const benefitIndex = benefitsTypes.findIndex((benefit) => {
        const op = benefit.operators.find((operator) => {
          return operator.id === benefitUnderEdition.operatorId
        })
        cardOperator = op
        return op
      })

      form.setValue('operatorCard', benefitUnderEdition.operatorCardId)
      form.setValue('benefitType', benefitIndex)
      form.setValue('operator', cardOperator?.id)
      form.setValue('status', benefitToEdit.status)
      const total = String(Number(benefitToEdit.dailyRate).toFixed(2)).replace('.', ',')
      form.setValue('dailyValueTotal', total)
      form.setValue('dailyValue', total)
    },
  })

  // eslint-disable-next-line
  useEffect(() => {
    employeeId && getBenefitsRequest(employeeId)
    if (isAdding) form.reset()
  }, [])

  useEffect(() => {
    if (Boolean(benefitToEdit) && mode !== BindCardToEmployeeMode.add && benefits?.length)
      getBenefitToEditRequest(benefitToEdit?.benefitId)
    // eslint-disable-next-line
  }, [benefitToEdit, benefits])

  const isLoading =
    getBenefitsState.loading ||
    getCardFeesState.loading ||
    postBindBenefitState.loading ||
    getBenefitToEditState.loading ||
    putEditBindedBenefitState.loading

  const isValidCloseReason = (reason) => {
    return reason !== 'escapeKeyDown' && reason !== 'backdropClick'
  }

  const swapDialogs = () => {
    setIsCardSelectionOpen((state) => (state ? false : true))
    setIsFeeConfigurationOpen((state) => (state ? false : true))
  }
  const onSave = (data) => {
    const payload = {
      employeeId: employeeId,
      operatorCardId: data.operatorCard,
      cardNumber: data.cardNumber,
      operatorRegistration: data.operatorRegistration,
    }

    const hasFees = data.products?.length > 0
    if (hasFees) {
      payload.products = data.products
        .filter((product) => Boolean(product.productId?.[0]))
        .map((product) => ({
          id: product.productId[0],
          quantity: product.quantity,
        }))
    } else {
      payload.dailyValue = String(data.dailyValue).replace(',', '.')
    }

    if (isAdding) postBindBenefitRequest(payload)
    else {
      const benefitId = benefitsEdit.find((benefit) => benefit?.name === edit)?.benefitId

      payload.status = data.status
      putEditBindedBenefitRequest(benefitToEdit?.benefitId || benefitId, payload)
    }
    onClose(false)
  }

  const render = () => {
    if (mode === BindCardToEmployeeMode.view) {
      return (
        <BindVisualization
          form={form}
          employeeId={employeeId}
          benefits={benefits}
          cardsFeesData={cardsFeesData}
          isLoading={isLoading}
          requestFees={getCardFeesRequest}
          open={open}
          onSave={onSave}
          mode={mode}
          onClose={(_, reason) => {
            if (isValidCloseReason(reason)) {
              onClose(false)
            }
          }}
          swapDialogs={() => {
            setIsCardSelectionOpen(true)
            setMode(BindCardToEmployeeMode.edit)
          }}
        />
      )
    }
    if (isCardSelectionOpen) {
      return (
        <CardSelectionDialog
          form={form}
          employeeId={employeeId}
          benefits={benefits}
          cardsFeesData={cardsFeesData}
          isLoading={isLoading}
          requestFees={getCardFeesRequest}
          open={isCardSelectionOpen}
          setEdit={setEdit}
          onSave={onSave}
          mode={mode}
          onClose={(_, reason) => {
            if (isValidCloseReason(reason)) {
              setIsCardSelectionOpen(false)
              onClose(false)
            }
          }}
          swapDialogs={swapDialogs}
        />
      )
    }
    if (isFeeConfigurationOpen) {
      return (
        <FeeConfigurationDialog
          form={form}
          employeeId={employeeId}
          cardsFeesData={cardsFeesData?.dailyRates}
          open={isFeeConfigurationOpen}
          onSave={onSave}
          isLoading={isLoading}
          mode={mode}
          onClose={(_, reason) => {
            if (isValidCloseReason(reason)) {
              setIsFeeConfigurationOpen(false)
              onClose(false)
            }
          }}
          swapDialogs={swapDialogs}
        />
      )
    }
  }

  return render()
}
