import styled from '@emotion/styled'
import { DialogActions, DialogTitle } from '@stationkim/front-ui'

export const MainIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(243, 246, 249, 1);
  width: 96px;
  height: 96px;
  padding: 21.41px 20.77px 22.72px 19.36px;
  margin: 0 auto;
  border-radius: 96px;
`

export const Title = styled(DialogTitle)`
  disply: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin: 25px 0 10px;

  svg {
    width: 40px;
  }
`

export const Actions = styled(DialogActions)`
  margin-top: -22px;

  button {
    &:first-child {
      color: rgba(230, 81, 0, 1);
      border: 1px solid rgba(230, 81, 0, 1);
    }

    &:last-child {
      color: rgba(158, 158, 158, 1);
      border: 1px solid rgba(158, 158, 158, 1);
    }
  }
`
