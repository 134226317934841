import { useState } from 'react'
import { useAtomValue } from 'jotai'
import { ModalV2, Button, Message, Spinner } from 'presentation/components'
import { MaskedTextField } from 'presentation/components/molecules/maskedTextField/maskedTextField'
import { TextField as Input } from 'presentation/components/molecules/textField/textField'
import { Row } from 'react-grid-system'
import { Body, TitleModal, Flex, Label, Error } from './styles'
import { useForm } from 'react-hook-form'
import { AccountPersistentStore } from '@/main/store'
import api from '@/services/identity'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const CreateUser = ({ open, close, refresh, setMessage, setSuccess }) => {
  const store = useAtomValue(AccountPersistentStore)?.auth

  const [loading, setLoading] = useState(false)
  const [type, setType] = useState('')
  const [error, setError] = useState(false)
  const [messageError, setMessageError] = useState(false)
  const [phone, setPhone] = useState('')

  const schema = yup.object({
    name: yup.string().required().min(3),
    email: yup.string().required().email(),
    phone: phone ? yup.string().length(13) : null,
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      phone: '',
      email: '',
      name: '',
    },
  })

  const submit = async (data) => {
    setLoading(true)

    const phone = data.phone.replace(' ', '').replace('-', '')
    data.active = true

    try {
      await api.post(
        '/rh-bff/users/create/',
        { ...data, phone },
        {
          headers: { Authorization: `Bearer ${store?.accessToken}` },
        },
      )
      refresh()
      setLoading(false)
      setMessage('ok')
      setSuccess(true)
      setPhone('')
      reset({
        name: null,
        email: null,
        phone: '',
      })

      if (!type) {
        close()
      }
    } catch (e) {
      setMessageError(e?.response?.data?.errors[0]?.mensagem)
      setLoading(false)
      setError(true)
    }
  }

  return (
    <>
      <ModalV2 open={open} onClose={close} closeOnOverlayClick={false}>
        <Body>
          <Flex>
            <TitleModal>Adicionar Usuário</TitleModal>
          </Flex>
          <Row style={{ marginTop: '20px', marginLeft: '0px' }}>
            {loading && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '32px',
                }}
              >
                <Spinner />
              </div>
            )}

            {!loading && (
              <form style={{ width: '97%' }} onSubmit={handleSubmit(submit)} id='formCreate'>
                <Label>Nome</Label>
                <Input
                  type='text'
                  placeholder='Nome do colaborador'
                  {...register('name', { required: true })}
                  error={errors.name}
                />
                {errors.name && <Error>O nome deve conter no mínimo 3 caracteres</Error>}
                <div style={{ marginTop: '30px' }} />
                <Label>Email</Label>
                <Input
                  type='email'
                  placeholder='E-mail do colaborador'
                  {...register('email', { required: true })}
                  error={errors.email}
                />
                {errors.email && <Error>Para prosseguir você deve preencher este campo com E-mail válido</Error>}
                <div style={{ marginTop: '30px' }} />
                <Label>Celular (WhatsApp)</Label>
                <MaskedTextField
                  mask='00 00000-0000'
                  placeholder='WhatsApp do colaborador'
                  name='phone'
                  {...register('phone')}
                  error={errors.phone}
                  value={phone}
                  onChange={(value) => {
                    setPhone(value)
                    setValue('phone', value)
                  }}
                />
                {errors.phone && <Error>Digite um número válido!</Error>}
                <div style={{ marginTop: '30px' }} />
              </form>
            )}

            <Flex>
              <Button
                width='50%'
                variant='outlined'
                style={{ marginRight: '10px' }}
                type='submit'
                form='formCreate'
                onClick={() => setType('next')}
              >
                Adicionar e Criar Outro
              </Button>
              <Button width='50%' variant='action' type='submit' form='formCreate' onClick={() => setType('')}>
                Adicionar Usuário
              </Button>
            </Flex>
          </Row>
        </Body>
      </ModalV2>
      {error && (
        <Message type='error' onXClick={() => setError(false)}>
          {messageError ? <p style={{ width: '250px' }}>{messageError}</p> : 'Erro ao adicionar usuário'}
        </Message>
      )}
    </>
  )
}

export default CreateUser
