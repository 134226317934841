import { REPORTS_CATEGORIES } from '@/utils/constants'

export const reportsRoles = {
  [REPORTS_CATEGORIES.ORDERS]: [
    'relatorio_pedido_recarga',
    'relatorio_pedido_recarga_projecao',
    'relatorio_pedido_cartao',
  ],
  [REPORTS_CATEGORIES.CARDS]: [
    'relatorio_cartoes_sempedido',
    'relatorio_cartoes_bloqueado',
    'relatorio_cartoes_carregados_naocarregados',
  ],
  [REPORTS_CATEGORIES.BALANCE]: ['relatorio_saldo_analitico', 'relatorio_saldo_sintetico'],
}
