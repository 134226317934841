import { useEffect, useState } from 'react'
import {
  faDollarSign,
  faHouse,
  faKey,
  faList,
  faMoneyBillTransfer,
  faPaperclip,
  faUserGroup,
} from '@fortawesome/free-solid-svg-icons'
import { faCreditCard } from '@fortawesome/free-regular-svg-icons'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import {
  NavigationBar,
  UserIdentification,
  SideNavigationMenu,
  UserMenu,
  Balance,
} from 'presentation/components/molecules'
import defaultLogo from 'presentation/assets/defaultLogo.png'
import { Header, Logo, Icon } from 'presentation/components/atoms'
import Notification from 'presentation/components/notification'
import useService from 'main/hooks/useService'
import { getCreditBalance } from '@/services/dashboardServices'
import CartNotEmpty from 'presentation/assets/icons/cart-not-empty.svg'
import { Cart } from './styles'
import { Link } from 'react-router-dom'
import { useCart } from 'presentation/pages/cart/hooks/useCart'
import { FeatureToggle } from 'utils/featureToggle/featureToggle'
import { useAtomValue } from 'jotai'
import { AccountPersistentStore } from '@/main/store'
import { useAccessProfile } from '@/main/hooks/useAccessProfile'

const destinations = [
  {
    pathname: '/',
    icon: faHouse,
    displayName: 'Início',
  },
  {
    pathname: '/departamentos',
    icon: faList,
    displayName: 'Departamentos',
    role: 'departamentos_menu',
  },
  {
    pathname: '/colaboradores',
    icon: faUserGroup,
    displayName: 'Colaboradores',
    role: 'colaboradores_menu',
  },
  {
    pathname: '/cartoes',
    icon: faCreditCard,
    displayName: 'Cartões',
    role: 'cartoes_menu',
  },
  {
    pathname: '/recargas',
    icon: faMoneyBillTransfer,
    displayName: 'Recargas',
    role: 'recargas_menu',
  },
  {
    pathname: '/financeiro',
    icon: faDollarSign,
    displayName: 'Financeiro',
    role: 'financeiro_menu',
  },
  {
    pathname: '/relatorios',
    icon: faPaperclip,
    displayName: 'Relatórios',
    role: 'relatorios_menu',
  },
]
const userDestinations = [
  {
    pathname: '/usuarios',
    icon: faKey,
    displayName: 'Acessos e permissões',
    role: 'usuarios_menu',
  },
]

export const PageHeader = () => {
  const { menus: userMenu, resources } = useAccessProfile({ menu: userDestinations, resource: 'recarga' })
  const { menus } = useAccessProfile({ menu: destinations })
  const newMenu = [destinations[0], ...menus]

  const account = useAtomValue(AccountPersistentStore)
  const [balanceState, balanceRequest] = useService(getCreditBalance, { silent: true })
  const balance = balanceState.response?.data
  const isSigned = Boolean(account?.auth?.accessToken)
  const {
    cart: { rechargeOrders, firstCopyOrders, secondCopyOrders },
  } = useCart({ config: { enabled: isSigned } })
  useEffect(() => {
    if (account?.company?.id && isSigned) balanceRequest(account.company.id)
    // eslint-disable-next-line
  }, [])

  const itensInCart = rechargeOrders?.length + firstCopyOrders?.length + secondCopyOrders?.length || 0
  const [anchorEl, setAnchorEl] = useState(null)
  const [openDrawer, setOpenDrawer] = useState(false)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDrawerOpen = () => {
    setOpenDrawer(true)
  }

  const handleDrawerClose = () => {
    setOpenDrawer(false)
  }

  return (
    <Header>
      <Notification />
      {!isSigned ? null : (
        <Icon
          icon={faBars}
          onClick={handleDrawerOpen}
          sx={{
            height: '36px',
            marginRight: '32px',
            display: { sm: 'initial', md: 'none' },
          }}
        />
      )}
      <Logo src={account?.resale?.images?.header || defaultLogo} sx={{ marginRight: '16px' }} />
      {!isSigned ? null : (
        <>
          <NavigationBar destinations={newMenu} />
          <SideNavigationMenu destinations={newMenu} open={openDrawer} onClose={handleDrawerClose} />

          {resources({ item: 'recarga_carteiradigital' }) ? (
            <Balance
              sx={{ marginLeft: 'auto', marginRight: '36px', '&:nth-of-type(2)': { marginLeft: '16px' } }}
              value={balance || 0}
              isLoading={balanceState.loading}
              accountName={account?.resale?.digitalAccountName}
            />
          ) : null}
          <UserIdentification
            userName={account?.user?.name || 'Usuário'}
            companyName={account?.resale?.name || 'Empresa'}
            profilePicSrc={account?.user?.urlPhoto}
            onClick={handleClick}
          />
          <UserMenu
            destinations={userMenu}
            anchorEl={anchorEl}
            id='account-menu'
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            hideTerm={false}
          />
          {resources({ item: 'recarga_carrinho' }) ? (
            <FeatureToggle feature='cart'>
              <Cart>
                <Link to='/carrinho'>
                  <p>{itensInCart}</p>
                  <img src={CartNotEmpty} alt='cart' />
                </Link>
              </Cart>
            </FeatureToggle>
          ) : null}
        </>
      )}
    </Header>
  )
}
