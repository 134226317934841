import { ReactNode, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetAtom } from 'jotai'
import { atomWithHash } from 'jotai-location'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { AutoTable, Grid, PageTitle, Search, TableFooterPagination } from '@stationkim/front-ui'
import { Content, Panel } from 'presentation/components'
import { Button, Icon } from 'presentation/components/atoms'
import { DropdownButton, DropdownButtonItem } from 'presentation/components/molecules'
import { IOrderRow, useRechargesList } from './hooks/useRechargesList'
import { FilterOption, HeaderFilter } from './style'
import { ReactComponent as CirclePlusIcon } from 'presentation/assets/icons/circle-plus.svg'
import { useRechargesListColumnsConfiguration } from './hooks/useRechargesListColumnsConfiguration'
import { useFilter, usePagination } from '@/main/hooks'
import { EStatusCompra, EStatusPedido } from '@/main/enums'
import { NewFilter } from './components/newFilter/newFilter'
import { CancelPurchaseDialog } from './components/cancelPurchaseDialog/cancelPurchaseDialog'
import { useAccessProfile } from '@/main/hooks/useAccessProfile'

const filterAtom = atomWithHash<object | null>('rechargesFilter', null)
const paginationAtom = atomWithHash<object>('rechargesPagination', {})

const mapTabToOrderStatus = {
  1: EStatusCompra.AguardandoPagamento,
  2: EStatusPedido.EmProcessamento,
  3: EStatusPedido.Finalizado,
  4: null,
}

const initialQuery = { OrdenarPor: 'NumeroPedido', OrdenarDecrescente: true }

export const RechargesList = ({ listSelector }: { listSelector: JSX.Element }) => {
  const { resources } = useAccessProfile({ resource: 'recarga' })

  const navigate = useNavigate()
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [orderToCancel, setOrderToCancel] = useState<IOrderRow | null>(null)

  const { pagination, goToPage, setPageSize } = usePagination({
    paginationAtom,
    outputMap: {
      page: 'NumeroPagina',
      pageSize: 'TamanhoPagina',
    },
  })
  const { filter, updateFilter, overrideFilter, resetFilter } = useFilter({
    filterAtom,
    initialValue: initialQuery,
  })
  const rechargesList = useRechargesList({ filter, pagination })

  const columns = useRechargesListColumnsConfiguration({ updateFilter, setOrderToCancel })

  const handleTabNavigation = (e: any) => {
    const selectedTab = e.target.getAttribute('data-status')
    if (!selectedTab) return
    goToPage(1)
    if (selectedTab === '4') overrideFilter(initialQuery)
    else if (selectedTab === '1') overrideFilter({ ...initialQuery, statusCompra: EStatusCompra.AguardandoPagamento })
    else overrideFilter({ ...initialQuery, status: mapTabToOrderStatus[selectedTab] })
  }

  const getActualTab = () => {
    const actualStatusFilter = filter?.status
    if (filter?.statusCompra === EStatusCompra.AguardandoPagamento) return 1
    for (const key in mapTabToOrderStatus) {
      if (mapTabToOrderStatus[key] === actualStatusFilter) return key
    }
    return 4
  }

  return (
    <Content>
      <Panel noPadding>
        <Grid container>
          <Grid item xs={12} md>
            <PageTitle pageTitle='Recargas' />
          </Grid>
          <Grid item xs={12}>
            {
              <HeaderFilter activeFilter={getActualTab()} onClick={handleTabNavigation}>
                <FilterOption data-status='1' id='order_tab_waitingPayment'>
                  Aguardando pagamento
                </FilterOption>
                <FilterOption data-status='2' id='order_tab_running'>
                  Em processamento
                </FilterOption>
                <FilterOption data-status='3' id='order_tab_finished'>
                  Concluído
                </FilterOption>
                <FilterOption data-status='4' id='order_tab_all'>
                  Todos
                </FilterOption>
              </HeaderFilter>
            }
          </Grid>
        </Grid>
        <Grid container spacing='16px' padding='16px' alignItems='center'>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Search
              id='order_search'
              onSearch={(value) => {
                goToPage(1)
                updateFilter({
                  numeroPedido: value,
                })
              }}
              placeholder='Buscar na tabela abaixo ...'
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <Button
              id='order_button_filter'
              startIcon={<Icon icon={faFilter} />}
              variant='outlined'
              onClick={() => setIsFilterOpen(true)}
              fullWidth
            >
              Filtrar
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md='auto' sx={{ marginLeft: { md: 'auto' } }}>
            <DropdownButton
              sx={{ '& svg': { height: '24px' } }}
              startIcon={<CirclePlusIcon />}
              label='Novo pedido'
              fullWidth
              size='medium'
              id='order_button_newOrder'
            >
              {resources({ item: 'recarga_pedidomanual' }) ? (
                <DropdownButtonItem
                  id='order_button_newOrder_option_1'
                  onClick={() => navigate('/recargas/novo_pedido_manual_cartoes')}
                >
                  Pedido manual
                </DropdownButtonItem>
              ) : null}
              {resources({ item: 'recarga_pedidobroker' }) ? (
                <DropdownButtonItem
                  id='order_button_newOrder_option_2'
                  onClick={() => navigate('/recargas/pedido_recarga_broker', { state: { isDealer: false } })}
                >
                  Pedido Broker
                </DropdownButtonItem>
              ) : null}
              {resources({ item: 'recarga_pedidoimportacao' }) ? (
                <DropdownButtonItem
                  id='order_button_newOrder_option_3'
                  onClick={() => navigate('/recargas/novo_pedido_importacao')}
                >
                  Pedido por importação
                </DropdownButtonItem>
              ) : null}
            </DropdownButton>
          </Grid>
          <Grid
            item
            xs='auto'
            sm='auto'
            md='auto'
            sx={{
              margin: { xs: 'auto', sm: 'initial' },
              marginLeft: { sm: 'auto', md: 'initial' },
            }}
          >
            {listSelector}
          </Grid>
          <Grid item xs={12}>
            <AutoTable
              columns={columns}
              rows={rechargesList.rows}
              isLoading={rechargesList.isLoading}
              onRowClick={({ row }) => {
                if (EStatusPedido.NoCarrinho === row.status)
                  navigate('/recargas/pedido_recarga_broker/pagamento', {
                    state: {
                      isDealer: false,
                      purchaseId: row.purchaseIdEncrypted,
                      title: 'Recarga',
                    },
                  })
              }}
              link={({ row }) => {
                if (EStatusPedido.NoCarrinho === row.status) return '#'
                return `/recargas/acompanhamento/#orderId="${row.id}"&purchaseId="${row.purchaseId}"&isDealer=false`
              }}
            />
          </Grid>
        </Grid>
        {
          <TableFooterPagination
            id='orders_paginator'
            totalItems={rechargesList.totalItens}
            rowsPerPage={rechargesList.pageSize}
            totalPages={rechargesList.totalPages}
            page={rechargesList.page}
            onRowsPerPageChange={(e: any) => setPageSize(e.target.value)}
            onPageChange={(e, value) => goToPage(Number(value))}
          />
        }
      </Panel>
      <NewFilter
        open={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        filter={filter}
        onFilter={(data) => {
          goToPage(1)
          updateFilter(data)
          setIsFilterOpen(false)
        }}
        resetFilters={resetFilter}
      />
      {orderToCancel && <CancelPurchaseDialog order={orderToCancel} onClose={() => setOrderToCancel(null)} />}
      {orderToCancel && <CancelPurchaseDialog order={orderToCancel} onClose={() => setOrderToCancel(null)} />}
    </Content>
  )
}
