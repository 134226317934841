import { ReactElement, ReactNode, useCallback } from 'react'
import { Theme } from '@mui/material'
import { Controller, UseFormReturn } from 'react-hook-form'
import { Box, FieldTableVirtualizedProps, MaskedTextField, TextField, Tooltip } from '@stationkim/front-ui'
import { EditableCellContainer } from '../styles'
import { EFieldMasks, getMaskConfiguration } from '../shared'

interface EditableCellProps {
  rowIndex: number
  columnIndex: number
  columns: FieldTableVirtualizedProps['columns']
  rows: FieldTableVirtualizedProps['rows']
  content: ReactNode
  isEditing: boolean
  control: UseFormReturn<any>['control']
  setValue: UseFormReturn<any>['setValue']
}

const getEmptyValue = (maskType?: EFieldMasks) => {
  if ([EFieldMasks.Numeric, EFieldMasks.Monetary].includes(maskType as EFieldMasks)) return '0'
  return ''
}

const MemoizedComponent = ({
  columnIndex,
  columns,
  rowIndex,
  rows,
  content,
  isEditing,
  control,
  setValue,
}: EditableCellProps) => {
  const { editable: isFieldEditable = true, dataKey, mask: columnMask, dependents } = columns[columnIndex]
  const rules = columns[columnIndex].rules || {}
  const errors = rows[rowIndex].errors || {}
  const hasRowErrors = Object.keys(errors).length > 0

  const columnError = rows[rowIndex].errors?.[dataKey]

  const getTheme = useCallback(
    (theme: Theme, hasError?: boolean) => {
      let commonStyle: object = {
        border: (columnError || hasError) && isEditing ? '1px solid red' : 'none',
        borderRadius: '4px',
        transition: 'background-color .15s ease-in-out',
        backgroundColor: 'transparent',
        '& input': {
          fontSize: '0.9rem',
          color: columnError || hasError ? theme.palette.error.main : theme.palette.grey[700],
        },
      }

      if (isEditing && isFieldEditable)
        commonStyle = {
          ...commonStyle,
          backgroundColor: theme.palette.common.white,
        }
      return commonStyle
    },
    [columnError, isEditing, isFieldEditable],
  )

  const renderFieldOrEmpty = useCallback(
    (value: ReactNode, component: ReactElement): ReactElement => {
      const isEmpty = !isFieldEditable && value === ''
      if (isEmpty) return <Box sx={{ display: 'flex', justifyContent: 'center' }}>-</Box>
      return component
    },
    [isFieldEditable],
  )
  const mask = getMaskConfiguration(columnMask)

  return (
    <EditableCellContainer hasError={Boolean(columnError)} isMarked={columnIndex === 0 && hasRowErrors}>
      <Tooltip title={columnError}>
        <Box sx={{ width: '100%' }}>
          <Controller
            control={control}
            name={dataKey}
            rules={rules}
            defaultValue={rows[rowIndex][dataKey]}
            render={({ field: { value, onChange, ref }, fieldState: { error } }) => {
              const hasError = Boolean(error?.message)
              const baseProps = {
                ref: ref,
                sx: (theme: Theme) => getTheme(theme, hasError),
                size: 'small',
                value: !isEditing ? content : String(value),
                disabled: !isFieldEditable || !isEditing,
                onChange: !isEditing
                  ? null
                  : (e: any) => {
                      onChange(e)
                      const deps = typeof dependents === 'string' ? [dependents] : dependents
                      if (deps?.length > 0)
                        deps.forEach((dep: any) => {
                          const dependentColumn = columns.find((column: any) => column.dataKey === dep)
                          setValue(dep, getEmptyValue(dependentColumn?.mask))
                        })
                    },
              }
              return renderFieldOrEmpty(
                value,
                <Tooltip title={error?.message} disableHoverListener>
                  <Box sx={{ '& label': { gap: 0 } }}>
                    {mask.mask ? (
                      <MaskedTextField
                        sx={{ background: 'red', color: 'blue !important' }}
                        {...(baseProps as any)}
                        mask={mask}
                        unmask
                      />
                    ) : (
                      <TextField {...(baseProps as any)} />
                    )}
                  </Box>
                </Tooltip>,
              )
            }}
          />
        </Box>
      </Tooltip>
    </EditableCellContainer>
  )
}

export const EditableCell = MemoizedComponent
