import React, { useEffect } from 'react'
import { faX } from '@fortawesome/free-solid-svg-icons'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
} from 'presentation/components/atoms'
import { MaskedTextField } from 'presentation/components/molecules'
import { TextField } from 'presentation/components/molecules/textField/textField'
import { Controller, useFieldArray } from 'react-hook-form'
import { Multiselect } from 'presentation/components/organisms/multiselect'
import { formatMoney } from 'utils/functionUtils'
import { DialogContentText } from '@mui/material'

export const FeeConfigurationDialog = ({ form, open, cardsFeesData, onSave, onClose, isLoading, swapDialogs }) => {
  const { fields, remove } = useFieldArray({
    control: form.control,
    name: 'products',
  })

  const productsValue = form.watch('products')

  const onSubmit = (data) => {
    onSave(data)
  }

  const totalValue = productsValue
    ? productsValue.reduce((total, actualProduct) => {
        const actualFee = cardsFeesData.find((fee) => fee?.productId === actualProduct?.productId?.[0])
        const actualFeeValue = actualFee?.value
        return total + actualProduct?.quantity * actualFeeValue
      }, 0)
    : 0

  const removeFee = (feeId, index) => {
    const selectedsProductsIds = form.getValues('productsIds')
    const newSelectedsProductsIds = selectedsProductsIds.filter((product) => product !== feeId?.[0])
    form.setValue('productsIds', newSelectedsProductsIds)
    remove(index)
  }

  const renderFeesFields = () => {
    return (
      <Grid container columnSpacing='16px' rowSpacing='16px' justifyContent='flex-end' sx={{ paddingRight: '16px' }}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          fields.map((field, index) => {
            if (!field.productId) return null
            const fieldRowName = `products.${index}.`
            return (
              <React.Fragment key={field.id}>
                <Grid item xs={7}>
                  <Controller
                    name={fieldRowName + 'productId'}
                    control={form.control}
                    render={({ field: { value, onChange } }) => (
                      <Multiselect
                        label={`Tarifa ${index + 1}`}
                        variant='outlined'
                        value={value}
                        onChange={(newValue) => {
                          if (newValue.length === 0) {
                            removeFee(value, index)
                            if (fields.length === 1) swapDialogs()
                          } else onChange(newValue)
                        }}
                        fullWidth
                        id={'employeeBenefitDialog_' + fieldRowName + 'productId'}
                        disabled
                        options={cardsFeesData}
                        getOptionLabel={(option) => option?.description}
                        getOptionValue={(option) => option?.productId}
                        IconComponent={() => null}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2.5}>
                  <Controller
                    name={fieldRowName + 'quantity'}
                    control={form.control}
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <TextField
                        label='Qtd.'
                        placeholder='00'
                        value={value}
                        success={!error}
                        id={'employeeBenefitDialog_' + fieldRowName + 'quantity'}
                        error={!!error}
                        sx={{ '& input': { textAlign: 'center' } }}
                        onChange={(e) => {
                          const newValue = e.target.value.replaceAll(/[^0-9]/g, '')
                          if (newValue > 10) return
                          const actualFee = cardsFeesData.find((fee) => fee?.productId === field?.productId?.[0])
                          const actualFeeValue = actualFee?.value
                          form.setValue(fieldRowName + 'dailyValue', String(Number(newValue) * actualFeeValue))
                          onChange(newValue)
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2.5}>
                  <Controller
                    name={fieldRowName + 'dailyValue'}
                    control={form.control}
                    render={({ field: { value } }) => (
                      <MaskedTextField
                        mask={Number}
                        radix=','
                        padFractionalZeros={true}
                        label='Valor Diário'
                        id={'employeeBenefitDialog_' + fieldRowName + 'dailyValue'}
                        blocked
                        startAdornment='R$'
                        placeholder='00,00'
                        value={value ? value.replace('.', ',') : '0'}
                      />
                    )}
                  />
                </Grid>
              </React.Fragment>
            )
          })
        )}
      </Grid>
    )
  }

  return (
    <Dialog open={open} maxWidth='md' onClose={onClose}>
      <DialogTitle id='employeeBenefitDialog_title'>
        Configurar Tarifas
        <Icon
          icon={faX}
          id='employeeBenefitDialog_close'
          onClick={isLoading ? null : onClose}
          sx={{ marginLeft: '130px' }}
        />
      </DialogTitle>
      <DialogContentText sx={{ marginTop: '16px' }}>
        Informe, para cada tarifa registrada, o número de vezes o qual <br />
        ela será utilizada
      </DialogContentText>
      {isLoading ? (
        <CircularProgress
          sx={{
            margin: '32px auto',
          }}
        />
      ) : (
        <>
          <DialogContent>
            <form
              id='bindedCardFees'
              onSubmit={form.handleSubmit(onSubmit)}
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '32px',
                margin: '32px 0',
                height: '288px',
                overflow: 'auto',
              }}
            >
              {renderFeesFields()}
            </form>
            <Box xs='auto' id='employeeBenefitDialog_label_total' sx={{ marginBottom: '32px', textAlign: 'right' }}>
              Total: R$ {formatMoney(totalValue || 0)}
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'space-between' }}>
            <Button id='employeeBenefitDialog_button_goBack' onClick={swapDialogs}>
              Voltar
            </Button>
            <Button
              id='employeeBenefitDialog_button_save'
              variant='contained'
              sx={{ width: '248px' }}
              type='submit'
              form='bindedCardFees'
            >
              Salvar
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}
