import { ORDER_STATUS } from './constants'

export const statusToStep = ({ status, isFinancedSales }: { status: number; isFinancedSales: boolean }) => {
  const stepsMap = [
    [ORDER_STATUS.EFFECTING, ORDER_STATUS.CANCELED, ORDER_STATUS.CANCELING],
    isFinancedSales && ORDER_STATUS[status] === 'Aguardando pagamento'
      ? [ORDER_STATUS.WAITING_PAYMENT]
      : [ORDER_STATUS.WAITING_PAYMENT, 99],
    [ORDER_STATUS.WAITING_FOR_PROCESSING, ORDER_STATUS.PROCESSING, ORDER_STATUS.RUNNING],
    [ORDER_STATUS.AVAILABLE_PICKUP, ORDER_STATUS.DELIVERING],
    [ORDER_STATUS.FINISHED],
  ]

  return isFinancedSales
    ? stepsMap.findIndex((stepStatus) => stepStatus.includes(status)) + 1
    : stepsMap.findIndex((stepStatus) => stepStatus.includes(status))
}
