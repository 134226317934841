import { SxProps } from '@mui/material'
import { faCheck, faPen, faX } from '@fortawesome/free-solid-svg-icons'
import { faTrashCan } from '@fortawesome/free-regular-svg-icons'
import { Icon, IconButton, Tooltip, Typography, Box } from '@stationkim/front-ui'

interface ActionsProps {
  isEditable: boolean
  isDeletable: boolean
  isEditing: boolean
  isDeleting: boolean
  isLoading: boolean
  handlers: {
    handleEdit: () => void
    handleEditionConfirm: () => void
    handleEditionCancel: () => void
    handleDelete: () => void
    handleDeleteConfirm: () => void
    handleDeleteCancel: () => void
  }
  sx?: SxProps
}

export const Actions = ({
  isEditable,
  isDeletable,
  isEditing,
  isDeleting,
  isLoading,
  sx,
  handlers: {
    handleEdit,
    handleEditionConfirm,
    handleEditionCancel,
    handleDelete,
    handleDeleteConfirm,
    handleDeleteCancel,
  },
}: ActionsProps) => {
  const isManipulating = isEditing || isDeleting

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '8px',
        justifyContent: 'end',
        width: '100%',
        padding: '16px 0',
        ...sx,
      }}
    >
      {isEditable && !isManipulating && !isLoading ? (
        <Tooltip title='Editar' placement='top'>
          <IconButton aria-label='editar' onClick={handleEdit}>
            <Icon icon={faPen} />
          </IconButton>
        </Tooltip>
      ) : null}
      {isDeletable && !isManipulating && !isLoading ? (
        <Tooltip title='Excluir' placement='top'>
          <IconButton aria-label='excluir' onClick={handleDelete} color='error'>
            <Icon icon={faTrashCan} />
          </IconButton>
        </Tooltip>
      ) : null}

      {isManipulating && !isLoading ? (
        <>
          {isDeleting && <Typography variant='body2'>Excluir?</Typography>}
          {isEditing && <Typography variant='body2'>Editar</Typography>}
          <Tooltip title='Confirmar' placement='top'>
            <IconButton
              aria-label='confirmar'
              color='success'
              onClick={isEditing ? handleEditionConfirm : handleDeleteConfirm}
              type={isEditing ? 'submit' : 'button'}
            >
              <Icon icon={faCheck} />
            </IconButton>
          </Tooltip>
          <Tooltip title='Cancelar' placement='top'>
            <IconButton
              aria-label='cancelar'
              onClick={isEditing ? handleEditionCancel : handleDeleteCancel}
              color='error'
            >
              <Icon icon={faX} />
            </IconButton>
          </Tooltip>
        </>
      ) : null}
    </Box>
  )
}
