import moment from 'moment'

import { BENEFIT_TYPES } from '@/utils/constants'
import { useAccessProfile } from '@/main/hooks/useAccessProfile'

import { TableCell, TableRow, TableBody as Body } from 'presentation/components/atoms'

export const TableBody = ({ cards }) => {
  const { resources } = useAccessProfile({ resource: 'cartoes' })

  return (
    <Body>
      {cards?.map((card, index) => (
        <TableRow style={{ cursor: 'pointer' }} key={'blocked_card_' + index}>
          <TableCell>{moment(new Date(card.blockDate)).utc().format('DD/MM/YYYY')}</TableCell>
          <TableCell>{card.benefitName}</TableCell>
          <TableCell>{card.number}</TableCell>
          <TableCell>{card.employeeName}</TableCell>
          <TableCell>{card.employeeIntegrationCode}</TableCell>
          <TableCell>{BENEFIT_TYPES[card.benefitType]}</TableCell>
          {resources({ item: 'cartoes_saldo' }) ? <TableCell>R$ {card.balance}</TableCell> : null}
        </TableRow>
      ))}
    </Body>
  )
}
