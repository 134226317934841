import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNotifications } from 'main/hooks'
import { EMessageType } from 'main/store'
import api from '@/infra/api'

export const useCancelOrder = (params: { onSuccess?: () => void }) => {
  const { onSuccess } = params || {}
  const queryClient = useQueryClient()
  const notify = useNotifications()

  const onSuccessCommonBehavior = () => {
    notify.push({ content: 'Pedido cancelado!', type: EMessageType.Success })
    onSuccess && onSuccess()
    queryClient.invalidateQueries(['recharges'])
    queryClient.invalidateQueries(['cart'])
  }

  const cancelOrder = useMutation({
    mutationFn: ({ orderId }: { orderId: string }) => api.patch(`rh-bff/cards/${orderId}/cancel`),
    onSuccess: onSuccessCommonBehavior,
  })

  const cancelCartOrders = useMutation({
    mutationFn: (payload: Array<string>) => api.patch(`rh-bff/basket/cancelOrders`, payload),
    onSuccess: onSuccessCommonBehavior,
  })

  return {
    cancelOrder,
    cancelCartOrders,
  }
}
