import api from '@/infra/api'
import apiCallHandler from './apiCallHandler'

export const getCards = (query = '') => {
  return apiCallHandler(() => api.get(`rh-bff/cards${query}`))
}

export const getCardsBlocked = (query = '') => {
  return apiCallHandler(() => api.get(`rh-bff/cards/blocked${query}`))
}

export const patchCancelCardOrder = (orderId) => {
  return apiCallHandler(() => api.patch(`rh-bff/cards/${orderId}/cancel`))
}

export const patchConfirmCardOrder = (orderId, payload) => {
  return apiCallHandler(() => api.patch(`rh-bff/cards/new/confirmation/${orderId}/confirm`, payload))
}

export const patchPayConfirmCardOrder = (orderId) => {
  return apiCallHandler(() => api.patch(`rh-bff/cards/new/confirmation/${orderId}/pay`))
}

export const getCardsOrderSlip = (orderId) => {
  return api.get(`rh-bff/cards/orders/${orderId}/slip`)
}

export const patchDisassociateCard = (cardId) => {
  return apiCallHandler(() => api.patch(`rh-bff/cards/${cardId}/disassociate`))
}

export const patchAssociateCard = async (cardId, employeeId) => {
  return apiCallHandler(() => api.patch(`rh-bff/cards/${cardId}/associate/${employeeId}`))
}

export const getBlockReasons = async (cardId) => {
  return apiCallHandler(() => api.get(`rh-bff/cards/${cardId}/block/reasons`))
}

export const patchBlockCard = async (cardId, reason) => {
  return apiCallHandler(() => api.patch(`rh-bff/cards/${cardId}/block`, reason))
}

export const getCardsFilterData = () => {
  return apiCallHandler(() => api.get(`rh-bff/cards/filter`))
}

export const patchCardStatus = (cardId, status) => {
  return apiCallHandler(() => api.patch(`rh-bff/cards/${cardId}/activate/${status}`))
}
