import { SummaryContainer, SummaryHeader } from './styles'
import { formatMoney } from 'utils/functionUtils'
import { StatusStepper } from './components/statusStepper'
import { IOrder, orderTypeVariations } from './utils'
import { ValuesSummary, ValuesSummaryProps } from '../valuesSummary/valuesSummary'
import { Summary, SummaryProps } from '../summary/summary'
import { Box, Typography } from 'presentation/components/atoms'
import { statusToStep } from '@/main/utils/statusStepper'

interface OrderSummaryProps extends ValuesSummaryProps, SummaryProps {
  order: IOrder
  showHeader?: boolean
  hideSubtitle?: boolean
  id?: string
}

export const OrderSummary = ({
  order,
  values,
  title,
  subtitle,
  hideSubtitle = false,
  icon,
  showHeader = false,
  id,
}: OrderSummaryProps) => {
  const textsToBeUsed = orderTypeVariations({ orderType: order.orderType, isFinancedSales: order.isFinancedSales })

  const OrderIcon = textsToBeUsed.icon

  return (
    <SummaryContainer>
      {showHeader && (
        <SummaryHeader>
          <span>
            Pedido: <b>{order.orderCode}</b>
          </span>
          <span>
            Cartão: <b>{order.operatorCardName}</b>
          </span>
        </SummaryHeader>
      )}
      <StatusStepper
        status={statusToStep({ status: order.status, isFinancedSales: order?.isFinancedSales })}
        statusArray={textsToBeUsed.status}
        isFinancedSales={order?.isFinancedSales}
      />
      <Summary
        id={id}
        title={
          title ?? (
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant='h4'>{textsToBeUsed.type}</Typography>
              <Typography variant='h4'>R$ {formatMoney(order.totalValue)}</Typography>
            </Box>
          )
        }
        subtitle={hideSubtitle ? undefined : subtitle ?? textsToBeUsed.generateItemsString(order.totalItens)}
        icon={icon ?? <OrderIcon id={id + '_arrow'} />}
      >
        <ValuesSummary values={values} />
      </Summary>
    </SummaryContainer>
  )
}
