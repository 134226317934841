import { IMask } from 'react-imask'

export enum EFieldMasks {
  CNPJ,
  CPF,
  Monetary,
  Numeric,
  Product,
}

export const getMaskConfiguration = (maskType?: EFieldMasks) => {
  switch (maskType) {
    case EFieldMasks.CNPJ:
      return { mask: '00.000.000/0000-00', lazy: false }
    case EFieldMasks.CPF:
      return { mask: '000.000.000-00', lazy: false }
    case EFieldMasks.Monetary:
      return {
        mask: 'R$ num',
        lazy: false,
        prepareChar: (value: string) => {
          if (!value) return ''
          return value.replace('.', ',')
        },
        blocks: {
          num: {
            mask: Number,
            scale: 2,
            thousandsSeparator: '.',
            padFractionalZeros: true,
            normalizeZeros: true,
            radix: ',',
            mapToRadix: ['.'],
          },
        },
      }
    case EFieldMasks.Product:
      return { mask: IMask.MaskedRange, maxLength: 4, autofix: true, from: 0, to: 9999 }
    case EFieldMasks.Numeric:
      return { mask: Number }
    default:
      return { mask: undefined }
  }
}
