import { styled } from '@mui/material'
import { Box } from '@stationkim/front-ui'

export const EditableCellContainer = styled(Box, {
  shouldForwardProp: (propName: string) => !['hasError', 'isMarked'].includes(propName),
})<{ hasError: boolean; isMarked: boolean }>(({ theme, isMarked, hasError }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  color: hasError ? theme.palette.error.main : 'auto',
  '&:after': {
    content: isMarked ? '" "' : 'unset',
    top: '-16px',
    left: '-16px',
    width: '2px',
    height: '56px',
    backgroundColor: theme.palette.error.dark,
    position: 'absolute',
  },
}))

export const AutoTableVirtualizedContainer = styled(Box, {
  shouldForwardProp: (propName: string) => !['hoverable'].includes(propName),
})<{ hoverable?: boolean }>(({ theme, hoverable = true }) => ({
  '& .BaseTable__row:hover, & .BaseTable__row--hovered': {
    backgroundColor: hoverable ? theme.palette.grey[50] : 'transparent',
  },
  '& .BaseTable__row[dataisdeleting=true]': {
    backgroundColor: theme.palette.error.main + '0D !important',
  },
  '.BaseTable__row[dataisediting=true]': {
    backgroundColor: theme.palette.grey[100],
  },
  '.BaseTable__row[datahaserror=true]': {
    boxShadow: 'inset 2px 0px 0px ' + theme.palette.error.dark,
  },
}))
